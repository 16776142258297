import React from 'react'
import Layout from '../../../../components/layout'
import Seo from '../../../../components/seo'

import Illustration from '../../../../svg/landing-page-main.svg'
import OurApproach from '../../../../components/landing/our-approach'
import LandingMainBanner from '../../../../components/landing/main-banner';
import ProblemOfSite from '../../../../components/landing/problem'
import Prices from '../../../../components/landing/prices'
import AboutSection from '../../../../components/page-parts/about-section';
import FormSection from '../../../../components/page-parts/form-section';
import Technologies from '../../../../components/page-parts/technologies'
import SitePageDescription from '../../../../components/landing/description'

const LandingPage = () => {

  const content = {
    ttlBlue: 'создание ',
    title: <span>сайта-квиза</span>,
    subtitle: 'Созднание сайта - это первый шаг на пути к узнаваемости вашего бизнеса. Мы проектируем и разрабатываем высокопроизводительные сайты, которые приводят реальных клиентов',
    buttonText: 'подробнее',
    buttonLink: '#description-block',
  }

  const pageTitle = 'Создание сайта-квиза под ключ'
  const pageDescription = 'Разработаем интерактивный сайт-квиз или встроим квиз в ваш сайт! Проанализируем бизнес, аудиторию, конкурентов. Разработаем продающий, современный дизайн. Настроим рекламу в Яндекс Директ и Google Ads. Закажите разработку своего сайта в веб-студии Ascent.'

  // const keywords = ''

  const aboutSite = {
    title: 'Разработка интерактивного сайта-квиза «под ключ»',
    description: 
    <>
    <p><strong>Сайт-квиз</strong> — это полноценный интерактивный сайт или надстройка к уже имеющемуся сайту. Целью создания такого сайта является максимальное <strong>взаимодействие пользователей</strong> с вашей страницей.</p>
    <p>Вы сможете быстро собрать контактные данные посетителей, узнать об их предпочтениях и целях покупки ваших товаров или услуг. Полученные данные можно так же использовать для улучшения страниц вашего основного сайта, так как будет более понятен <strong>портрет вашей целевой аудитории</strong>.</p>
    <p>Наша команда поможет разработать вам современный, запоминающийся дизайн сайта-квиза. Сделаем красивую анимацию для максимально приятного взаимодействия со страницей. При необходимости настроим рекламу и подключим CRM-систему.</p>
    </>,
    advantagesTitle: 'Преимущества сайта-квиза',
    advantages: [
      'Максимальное взаимодействие',
      'Высокая конверсия',
      'Быстрая окупаемость',
      'Быстрый сбор клиентской базы',
      'Скорость разработки',
    ],
  }

  const tariffs = {
    base: 'от 25 000 ₽',
    business: 'от 35 000 ₽',
    premium: 'от 50 000 ₽',
  }

  const stages = [
    {
      name: 'Аналитика',
      desc: 'На подготовительном этапе разработки мы проводим анализ проектов конкурентов, их слабые и сильные места. Анализируем вашу целевую аудиторию, их боли и потребности. На основании собранной информации, предлагаем различные пути решения. ',
      id: 't1',
    },
    {
      name: 'Прототипирование',
      desc: 'Проектируем наглядную структуру сайта. Описываем поведение каждого элемента на странице.  Расставляем триггеры. Пишем продающие тексты.',
      id: 't2',
    },
    {
      name: 'Верстка и программирование',
      desc: 'Верстаем утвержденный вами дизай и структуру сайта. Адаптируем под все устройства. Добавляем анимацию и интерактивность элементам на странице. Добавляем сайту функциональность для удобного взаимодействия с ним.',
      id: 't3',
    },
    {
      name: 'Настройка сервисов аналитики',
      desc: 'Устанавливаем на сайт сервисы аналитики (Яндекс.Метрика, Google Analytics). Настраиваем цели для отслеживания показателей конверсии и результативности сайта. Сбор аналитики для улучшения KPI.',
      id: 't4',
    },
    {
      name: 'Запуск в продакшн',
      desc: 'Проводим полное тестирование сайта. Выявляем на ошибки и проблемы. После успешной проверки запускаем сайт в онлайн. Добавляем сайт в Яндекс.Вебмастер и Google Search Console для видимости его поисковиками.',
      id: 't5',
    },
    {
      name: 'Поддержка',
      desc: 'Мы за долгосрочные и честные отношения! После сдачи проекта, мы предлагаем вам заключить договор для дальнейшего сотрудничества, на протяжении которого мы будем продолжать работать над улучшением показателей вашего сайта, внедрять новые «фичи», тестировать различные гипотезы.',
      id: 't6',
    },
  ]

  return (
    <Layout fixedHeader={true}>
      <Seo title={pageTitle} description={pageDescription} />
      <LandingMainBanner svg={<Illustration />} content={content} />
      <SitePageDescription content={aboutSite} />
      <Prices tariffs={tariffs} siteType="одностраничного сайта" />
      <ProblemOfSite />
      <OurApproach />
      <Technologies title="этапы разработки сайта" content={stages} mb={10}/>
      <AboutSection />
      <FormSection />
    </Layout>
  )
}

export default LandingPage
